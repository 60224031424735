import {useState} from "react";
import {
    defaultFormData,
    defaultValidated, fieldsToRemove,
    fieldsToValidate, fieldsToValidateCompany, fieldsToValidateCustomer,
    fieldsToValidatedWarranty, fieldsToValidateSelfDelivery,
    fieldsToValidateShipping
} from "./validationFields";
import axios from "axios";
import {API} from "./constants";
import {
    Alert,
    Button, Checkbox,
    Divider,
    FormControlLabel, Snackbar,
    styled,
    ThemeProvider,
    Tooltip,
    tooltipClasses,
    Typography
} from "@mui/material";
import {theme} from "./theme";
import {Item} from "./custom/item";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Logo from "./static/new_logo.png";
import {CientsOrders} from "./clientsOrders";
import AsyncAutocomplete from "./custom/AsyncAutocomplete";
import TextField from "@mui/material/TextField";
import {validate_email, validate_phone, validate_postal_code, validate_tax} from "./custom/validators";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import {MuiFileInput} from "mui-file-input";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LoginPage} from "./login";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const Form = () => {
    const [defaultKey, setDefaultKey] = useState(0);
    const [contractorKey, setContractorKey] = useState(0);
    const [formType, setFormType] = useState('');
    const [contractorType, setContractorType] = useState('default');
    const [isOpenSuccess, setIsOpenSuccess] = useState(false);
    const [shippingAddress, setShippingAddress] = useState(false);
    const [photo, setPhoto] = useState([null, null, null]);
    const [purchaseDocument, setPurchaseDocument] = useState(null);
    const [addPhotos, setAddPhotos] = useState(false);
    const [formData, setFormData] = useState(defaultFormData);
    const [fieldsValidated, setFieldsValidated] = useState(defaultValidated);
    const [message, setMessage] = useState('');
    const [severityMessage, setSeverityMessage] = useState('info');
    const [serviceAddress, setServiceAddres] = useState(false);
    const [serviceAddressData, setServiceAddressData] = useState({});
    const [sendSuccess, setSendSuccess] = useState(false);
    const [selectedCatalog, setCatalog] = useState(null);
    const [selectedCatalogImages, setCatalogImages] = useState([]);
    const [selectedDevice, setDevice] = useState(null);
    const [avaliableShippingMethod, setavaliableShippingMethod] = useState([]);
    const [shippingMethods, setShippingMethods] = useState(null);
    const [updateShipping, setUpdateShipping] = useState(0);
    const [shippingName, setShippingName] = useState(null);
    const [terms, setTerms] = useState(false);
    const [isOpenLoginPage, setIsOpenLoginPage] = useState(false);
    const [userEmail, setUserEmail] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userToken, setUserToken] = useState(null);
    const [userOrders, setUserOrders] = useState([]);


    const fieldsHidden = {
        'naturalPerson': {
            'taxId': true,
            'firstName': false,
            'lastName': false,
            'companyName': true,
        },
        'company': {
            'taxId': false,
            'firstName': true,
            'lastName': true,
            'companyName': false,
        },
        'default': {
            'taxId': true,
            'firstName': true,
            'lastName': true,
            'companyName': true,
        }
    }

    const getContractorType = () => {
        return {data: {results: [{name: 'Osoba fizyczna', value: 'naturalPerson'}, {name: 'Firma', value: 'company'}]}}
    }

    const getStates = async () => {
        return axios.get(`${API.STATES}all/`)
    }

    const getShippingOptions = () => {
        return axios.get(`${API.SHIPPING_OPTIONS}all/`)
        // return {
        //     data: [
        //         {name: 'Odbiór urządzenia przez kuriera zamówionego przez nas', value: 'delivery_company'},
        //         {name: 'Doręczenie osobiste', value: 'self_delivery'},
        //         {name: 'Wysyłka urządzenia kurierem we własnym zakresie', value: 'self_delivery'}
        //     ]
        // }
    }

    const getCountries = async () => {
        return axios.get(`${API.COUNTRIES}all/`)
    }

    const getDevices = async () => {
        await getShippingMethods()
        return axios.get(`${API.DEVICES}?device_catalog__uuid=${selectedCatalog}`)
    }

    const getDevicesCatalogs = async () => {
        return axios.get(`${API.DEVICES_CATALOGS}all/`)
    }

    const getOrderTypes = async () => {
        return axios.get(`${API.ORDER_TYPES}`)
    }
    const getServiceAddress = async () => {
        return axios.get(`${API.SERVICE_ADDRESS}`)
    }

    const getServiceAddressFiltered = async (device_uuid) => {
        return axios.get(`${API.SERVICE_ADDRESS}?devices__uuid=${device_uuid}`)
    }

    const createOrder = async (form) => {
        return axios.post(`${API.NEW_ORDER}`, form);
    }

    const getContractorData = async (tax_number) => {
        axios.get(`${API.GUS}${tax_number}/`).then((response) => {
            setFormData({...formData, ...response.data});
            setContractorKey(contractorKey + 1);
        }).catch(function (error) {
            openSnack('Nie znalezionio firmy w GUS!', 'error');
        });
    }
    const changeFieldValidated = (key, value) => {
        setFieldsValidated({...fieldsValidated, [key]: value})
    }

    const validateFields = () => {
        let validated = true;
        let newFieldsValidated = {};
        fieldsToValidate.forEach((key) => {
            if (formData[key] === null || formData[key] === undefined || formData[key] === '') {
                validated = false;
                newFieldsValidated[key] = false
            }
        })
        if (shippingAddress) {
            fieldsToValidateShipping.forEach((key) => {
                if (formData[key] === null || formData[key] === undefined || formData[key] === '') {
                    validated = false;
                    newFieldsValidated[key] = false
                }
            })
        }
        if (formType === 'Serwis gwarancyjny') {
            fieldsToValidatedWarranty.forEach((key) => {
                if (formData[key] === null || formData[key] === undefined || formData[key] === '') {
                    validated = false;
                    newFieldsValidated[key] = false
                }
            })
        }
        if (contractorType === 'company') {
            fieldsToValidateCompany.forEach((key) => {
                if (formData[key] === null || formData[key] === undefined || formData[key] === '') {
                    validated = false;
                    newFieldsValidated[key] = false
                }
            })
        }
        if (contractorType === 'naturalPerson') {
            fieldsToValidateCustomer.forEach((key) => {
                if (formData[key] === null || formData[key] === undefined || formData[key] === '') {
                    validated = false;
                    newFieldsValidated[key] = false
                }
            })
        }
        if (serviceAddress) {
            fieldsToValidateSelfDelivery.forEach((key) => {
                if (formData[key] === null || formData[key] === undefined || formData[key] === '') {
                    validated = false;
                    newFieldsValidated[key] = false
                }
            })
        }
        setFieldsValidated({...defaultValidated, ...newFieldsValidated})
        return validated
    }

    const getShippingMethods = async () => {
        axios.get(`${API.SHIPPING_METHODS}`).then(
            result => {
                setShippingMethods(result.data.results);
            }
        )
    }

    const getShippingMethod = async () => {
        let methods = [];
        avaliableShippingMethod.map(key => {
            shippingMethods.forEach(obj => {
                if (obj['uuid'] === key) {
                    methods.push(obj);
                }
            })
        })
        return {
            'data': methods
        }
    }

    const changeFormData = (key, value) => {
        setFormData({...formData, [key]: value});
    }

    const changeShippingAddress = () => {
        setShippingAddress(!shippingAddress);
    }

    const changeFormType = (e) => {
        if (e) {
            setFormType(e.name);
            changeFormData('order_type', e.uuid)
        } else {
            setFormType(null);
            changeFormData('order_type', null)
        }
    }

    const changeContractorType = (e) => {
        if (e) {
            changeFormData('contractor_type', e);
            setContractorType(e.value);
        } else {
            changeFormData('contractor_type', null);
            setContractorType('default');
        }

    }

    const handlePhotoChange = (e, key) => {
        if (e !== null) {
            if (e.size / 1000000 < 1.5) {
                setPhoto({...photo, [key]: e})
            } else {
                openSnack("Zbyt duży plik! Max 1.5MB.", 'error');
            }
        }
    }

    const handleFileChange = (e) => {
        if (e !== null) {
            if (e.size / 1000000 < 5) {
                setPurchaseDocument(e);
                changeFormData('purchase_document', e);
            } else {
                openSnack("Zbyt duży plik! Max 5MB.", 'error');
            }
        }
    }

    const changeDeviceCatalog = () => {
        setDevice(null);
        setFormData({
            ...formData, ...{
                'device': '',
                'shipping': '',
                'shipping_option': '',
                'service_address': null,
                'shipping_method': '',
            }
        })
        setServiceAddres(false);
        setServiceAddressData({});
    }
    const changeDevice = (uuid) => {
        setFormData({
            ...formData, ...{
                'shipping': '',
                'shipping_option': '',
                'service_address': null,
                'shipping_method': '',
                'device': uuid,
            }
        })
        setServiceAddres(false);
        setServiceAddressData({});
        setavaliableShippingMethod([]);
    }

    const openSnack = (message, severity) => {
        setMessage(message);
        setSeverityMessage(severity);
        setIsOpenSuccess(true);
    }

    const handleSave = async () => {
        formData['shipping_name'] = shippingName;
        let validated = validateFields();
        if (validated) {
            const form = new FormData();
            fieldsToRemove.forEach((value) => {
                delete formData[value];
            })
            Object.entries(formData).forEach(([k, v]) => {
                if (v !== null) {
                    form.append(k, v);
                }
            })
            Object.entries(photo).forEach(([k, v]) => {
                if (v !== null) {
                    form.append('form_files', v);
                }
            })
            createOrder(form).then(() => {
                setSendSuccess(true);
                openSnack("Zgłoszenie wysłane!", 'success');
                setFormData(defaultFormData);
                setShippingAddress(false);
                setFormType('');
                setContractorType('default');
                setPurchaseDocument(null);
                setPhoto([null, null, null])
                setTerms(false);
                setDefaultKey(1);
                setContractorKey(0);
            });
        }
    }

    const handleNewServiceOrder = () => {
        setSendSuccess(false);
    }

    const handleCloseSuccess = () => {
        setIsOpenSuccess(false);
    }
    const getOrders = (token = null) => {
        const instance = axios.create({
            baseURL: `${API.ORDERS}user/`,
            headers: {'Authorization': 'Bearer ' + (token !== null ? token : userToken)}
        });
        return instance.get()
    }
    const openLoginPage = () => {
        setIsOpenLoginPage(true);
    }
    const handleCloseLoginPage = () => {
        setIsOpenLoginPage(false);
    }
    const login = (username, password) => {
        axios.post(API.AUTH, {username: username, password: password}).then((response) => {
            setUserToken(response.data.access);
            getOrders(response.data.access).then(response => {
                setUserOrders(response.data);
                setIsLoggedIn(true);
                handleCloseLoginPage();
            })
        })
    }
    const logout = () => {
        setUserToken(null);
        setUserEmail(null);
        setUserOrders([]);
        setIsLoggedIn(false);
    }

    const HtmlTooltip = styled(({className, ...props}) => (
        <Tooltip {...props} classes={{popper: className}}/>
    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 460,
            overflowY: 'auto',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <header className="App-header">
                    <div>
                        <Item elevation={0}>
                            <Box sx={{padding: '40px', width: "1000px", maxHeight: "1400px", minHeight: "1000px"}}>
                                {!sendSuccess ?
                                    (<Box sx={{paddingBottom: "20px"}} key={defaultKey}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                                {isLoggedIn ?
                                                    (<Button onClick={() => logout()}>wyloguj</Button>)
                                                    :
                                                    (<Button onClick={() => openLoginPage()}>logowanie</Button>)
                                                }
                                            </Grid>
                                            <Grid item xs={11}></Grid>
                                            <Grid item xs={12}>
                                                <div>
                                                    <img src={Logo} style={{width: '20vh'}} alt="logo"/>
                                                </div>
                                            </Grid>
                                            {isLoggedIn ?
                                                (<><CientsOrders clientEmail={userEmail} ordersData={userOrders}/></>) :
                                                (<>
                                                    <Grid item xs={12}>
                                                        <div>
                                                            <Typography variant="h6">Formularz serwisowy</Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AsyncAutocomplete getData={getOrderTypes}
                                                                           label={'Typ zgłoszenia'}
                                                                           onChange={changeFormType}
                                                                           error={!fieldsValidated['order_type']}
                                                                           optionLabel={(option) => option.name}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AsyncAutocomplete getData={getContractorType} label={'Podmiot'}
                                                                           onChange={changeContractorType}
                                                                           error={!fieldsValidated['contractor_type']}
                                                                           optionLabel={(option) => option.name}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={9} hidden={fieldsHidden[contractorType]['taxId']}>
                                                        <TextField id="outlined-basic" label="NIP" variant="outlined"
                                                                   fullWidth
                                                                   disabled={false}
                                                                   onChange={(e) => {
                                                                       changeFormData('tax_number', validate_tax(e.target.value, changeFieldValidated))
                                                                   }}
                                                                   error={!fieldsValidated['tax_number']}
                                                                   defaultValue={formData['tax_number']}
                                                                   color='primary'
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3} hidden={fieldsHidden[contractorType]['taxId']}>
                                                        <Button variant="contained" color="primary"
                                                                onClick={(e) => getContractorData(formData['tax_number'])}
                                                                disabled={!formData['tax_number']}>
                                                            Pobierz dane z GUS
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}
                                                          hidden={fieldsHidden[contractorType]['companyName']}>
                                                        <TextField id="outlined-basic" label="Nazwa firmy"
                                                                   variant="outlined"
                                                                   fullWidth
                                                                   key={contractorKey}
                                                                   onChange={(e) => {
                                                                       changeFormData('contractor_name', e.target.value)
                                                                   }}
                                                                   disabled={false}
                                                                   defaultValue={formData['contractor_name']}/>
                                                    </Grid>

                                                    <Grid item xs={6}
                                                          hidden={fieldsHidden[contractorType]['firstName']}>
                                                        <TextField id="outlined-basic" label="Imię" variant="outlined"
                                                                   fullWidth
                                                                   disabled={false}
                                                                   onChange={(e) => {
                                                                       changeFormData('first_name', e.target.value)
                                                                   }}
                                                                   defaultValue=''/>
                                                    </Grid>
                                                    <Grid item xs={6} hidden={fieldsHidden[contractorType]['lastName']}>
                                                        <TextField id="outlined-basic" label="Nazwisko"
                                                                   variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('last_name', e.target.value)
                                                                   }}
                                                                   disabled={false}
                                                                   defaultValue=''/>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField id="outlined-basic" label="Ulica" variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('contractor_street', e.target.value)
                                                                   }}
                                                                   disabled={false}
                                                                   key={contractorKey}
                                                                   error={!fieldsValidated['contractor_street']}
                                                                   defaultValue={formData['contractor_street']}/>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <TextField id="outlined-basic" label="Nr budynku"
                                                                   variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('contractor_street_number', e.target.value)
                                                                   }}
                                                                   disabled={false}
                                                                   key={contractorKey}
                                                                   error={!fieldsValidated['contractor_street_number']}
                                                                   defaultValue={formData['contractor_street_number']}/>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <TextField id="outlined-basic" label="Nr lokalu"
                                                                   variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('contractor_home_number', e.target.value)
                                                                   }}
                                                                   disabled={false}
                                                                   key={contractorKey}
                                                                   error={!fieldsValidated['contractor_home_number']}
                                                                   defaultValue={formData['contractor_home_number']}/>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField id="outlined-basic" label="Kod pocztowy"
                                                                   variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('contractor_postal_code', validate_postal_code(e.target.value, changeFieldValidated, 'contractor_postal_code'))
                                                                   }}
                                                                   error={!fieldsValidated['contractor_postal_code']}
                                                                   disabled={false}
                                                                   key={contractorKey}
                                                                   defaultValue={formData['contractor_postal_code']}/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField id="outlined-basic" label="Miasto" variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('contractor_city', e.target.value)
                                                                   }}
                                                                   disabled={false}
                                                                   key={contractorKey}
                                                                   error={!fieldsValidated['contractor_city']}
                                                                   defaultValue={formData['contractor_city']}/>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <AsyncAutocomplete getData={getStates} label={'Województwo'}
                                                                           onChange={(e) => changeFormData('contractor_state', e ? e.name : '')}
                                                                           defaultValue={formData['contractor_state_obj']}
                                                                           error={!fieldsValidated['contractor_state']}
                                                                           key={contractorKey}
                                                                           optionLabel={(option) => option.name}

                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <AsyncAutocomplete getData={getCountries} label={'Kraj'}
                                                                           onChange={(e) => {
                                                                               changeFormData('contractor_country', e ? e.uuid : '');
                                                                           }}
                                                                           defaultValue={formData['contractor_country_obj']}
                                                                           error={!fieldsValidated['contractor_country']}
                                                                           key={contractorKey}
                                                                           optionLabel={(option) => option.name}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField id="outlined-basic" label="Email" variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('email', validate_email(e.target.value, changeFieldValidated))
                                                                   }}
                                                                   disabled={false}
                                                                   error={!fieldsValidated['email']}
                                                                   defaultValue={formData['email']}/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField id="outlined-basic" label="Nr telefonu"
                                                                   variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('phone_number', validate_phone(e.target.value, changeFieldValidated))
                                                                   }}
                                                                   disabled={false}
                                                                   error={!fieldsValidated['phone_number']}
                                                                   defaultValue={formData['phone_number']}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography sx={{ml: 1, flex: 1}} variant="h6" component="div"
                                                                    color={'grey'}>
                                                            Urządzenie
                                                            <Divider color={'grey'} fullWidth/>
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <AsyncAutocomplete getData={getDevicesCatalogs}
                                                                           label={'Typ urządzenia'}
                                                                           onChange={(e) => {
                                                                               changeDeviceCatalog();
                                                                               setCatalog(e ? e.uuid : null);
                                                                               setCatalogImages(e ? e.images : [])
                                                                           }}
                                                                           optionLabel={(option) => option.name}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <AsyncAutocomplete getData={getDevices} label={'Urządzenie'}
                                                                           key={selectedCatalog}
                                                                           onChange={(e) => {
                                                                               changeDevice(e?.uuid ? e.uuid : '');
                                                                               setDevice(e ? e.uuid : null);
                                                                               setavaliableShippingMethod(e ? e.shipping_method : [])
                                                                           }
                                                                           }
                                                                           optionLabel={(option) => option.name}
                                                                           error={!fieldsValidated['device']}
                                                                           disabled={selectedCatalog ? false : true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <HtmlTooltip
                                                            title={
                                                                <React.Fragment>
                                                                    <p>Jeżeli na liście nie widzisz swojego modelu
                                                                        urządzenia
                                                                        prosimy o kontakt mailowy na adres:
                                                                        serwis.df@wagner-polska.com.pl lub telefonicznie
                                                                        na
                                                                        numer: 507018289. Prosimy przygotować zdjęcia
                                                                        urządzenia/części.</p>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <IconButton>
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </HtmlTooltip>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField id="outlined-basic" label="Numer seryjny produktu"
                                                                   variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('serial_number', e.target.value)
                                                                   }}
                                                                   error={!fieldsValidated['serial_number']}
                                                                   disabled={false}
                                                                   defaultValue=''/>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <HtmlTooltip
                                                            placement="top"
                                                            title={
                                                                <React.Fragment>
                                                                    {selectedCatalogImages.length > 0 ?
                                                                        (<ImageList sx={{
                                                                            width: '100%',
                                                                            maxWidth: 460,
                                                                            overflowY: 'auto',
                                                                            height: `${selectedCatalogImages.length > 0 ? selectedCatalogImages.length * 4 : 300}`,
                                                                            maxHeight: 500
                                                                        }} cols={1}
                                                                        >
                                                                            {selectedCatalogImages.map((item, index) => {
                                                                                return (
                                                                                    <ImageListItem key={index}>
                                                                                        <img
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                maxWidth: 460,
                                                                                                height: "auto",
                                                                                                objectFit: 'cover'
                                                                                            }}
                                                                                            src={item.url}
                                                                                            alt={`image-${index}`}/>
                                                                                    </ImageListItem>
                                                                                )
                                                                            })
                                                                            }


                                                                        </ImageList>) : (
                                                                            <p>Numer seryjny nie jest obowiązkowy</p>
                                                                        )
                                                                    }

                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <IconButton>
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </HtmlTooltip>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField id="outlined-basic" label="Numer dowodu zakupu"
                                                                   variant="outlined" fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('purchase_document_number', e.target.value)
                                                                   }}
                                                                   disabled={false}
                                                                   error={!fieldsValidated['purchase_document_number']}
                                                                   defaultValue=''/>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <MuiFileInput value={purchaseDocument}
                                                                      onChange={handleFileChange}
                                                                      error={!fieldsValidated['purchase_document']}
                                                                      inputProps={{accept: '.png, .jpeg .pdf'}}
                                                                      helperText=".png .jpeg .pdf"
                                                                      placeholder={'Dowód zakupu '}/>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}
                                                                              adapterLocale="pl">
                                                            <DatePicker label={'Data zakupu'}
                                                                        fullWidth
                                                                        format='YYYY-MM-DD'
                                                                        slotProps={{
                                                                            textField: {
                                                                                error: !fieldsValidated['purchase_date']
                                                                            }
                                                                        }}
                                                                        onChange={(e) => {
                                                                            changeFormData('purchase_date', e.format('YYYY-MM-DD'))
                                                                        }}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AsyncAutocomplete getData={getShippingOptions}
                                                                           label={'Dostarczenie sprzętu'}
                                                                           onChange={(e) => {
                                                                               setFormData({
                                                                                   ...formData, ...{
                                                                                       'shipping_option': e?.uuid ? e.uuid : "",
                                                                                       'shipping': e?.code ? e.code : ""
                                                                                   }
                                                                               });
                                                                               setShippingName(e ? e.name : null)
                                                                           }}
                                                                           error={!fieldsValidated['shipping']}
                                                                           optionLabel={(option) => option.name}
                                                                           key={selectedCatalog}
                                                        />
                                                    </Grid>
                                                    {/*{*/}
                                                    {/*    formData['shipping'] === 'self_delivery' && selectedDevice ?*/}
                                                    {/*        (<Grid item xs={12}>*/}
                                                    {/*            <AsyncAutocomplete*/}
                                                    {/*                getData={() => getServiceAddressFiltered(selectedDevice)}*/}
                                                    {/*                key={selectedDevice}*/}
                                                    {/*                label={'Miejsce dostarczenia'}*/}
                                                    {/*                onChange={(e) => {*/}
                                                    {/*                    changeFormData('service_address', e ? e.uuid : '');*/}
                                                    {/*                    if (e && e.uuid) {*/}
                                                    {/*                        setServiceAddres(true);*/}
                                                    {/*                        setServiceAddressData(e);*/}
                                                    {/*                    } else {*/}
                                                    {/*                        setServiceAddres(false);*/}
                                                    {/*                        setServiceAddressData({});*/}
                                                    {/*                    }*/}
                                                    {/*                }}*/}
                                                    {/*                error={!fieldsValidated['service_address']}*/}
                                                    {/*                optionLabel={(option) => option.name}*/}
                                                    {/*            />*/}
                                                    {/*        </Grid>)*/}
                                                    {/*        :*/}
                                                    {/*        (<> </>)*/}
                                                    {/*}*/}
                                                    {/*{*/}
                                                    {/*    formData['shipping'] === 'self_delivery' && formData['service_address'] ?*/}
                                                    {/*        (<Grid item xs={12}>*/}
                                                    {/*            <Typography>*/}
                                                    {/*                ul.{serviceAddressData.street} {serviceAddressData.street_number}*/}
                                                    {/*            </Typography>*/}
                                                    {/*            <Typography>*/}
                                                    {/*                {serviceAddressData.postal_code} {serviceAddressData.city}*/}
                                                    {/*            </Typography>*/}
                                                    {/*            <Typography>*/}
                                                    {/*                Pracujemy:*/}
                                                    {/*            </Typography>*/}
                                                    {/*            <Typography>*/}
                                                    {/*                {serviceAddressData.opening_hours}*/}
                                                    {/*            </Typography>*/}
                                                    {/*        </Grid>)*/}
                                                    {/*        :*/}
                                                    {/*        (<> </>)*/}
                                                    {/*}*/}
                                                    <Grid item xs={3}>
                                                        {formData['shipping'] === 'delivery_company' ?
                                                            (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={shippingAddress}
                                                                            onChange={changeShippingAddress}
                                                                            name="shipping_address"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Inny adres do wysyłki"
                                                                />) : <></>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {
                                                            formData['shipping'] === 'delivery_company' ?
                                                                (
                                                                    <AsyncAutocomplete getData={getShippingMethod}
                                                                                       key={selectedDevice}
                                                                                       label={'Sposób pakowania'}
                                                                                       onChange={(e) => {
                                                                                           changeFormData('shipping_method', e ? e.uuid : '');
                                                                                       }}
                                                                        // error={!fieldsValidated['service_address']}
                                                                                       optionLabel={(option) => option.name}
                                                                    />
                                                                )
                                                                : (<></>)

                                                        }
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {
                                                            formData['shipping'] === 'delivery_company' ?
                                                                (
                                                                    <HtmlTooltip
                                                                        title={
                                                                            <React.Fragment>
                                                                                <p>W razie, gdy usterka urządzenia nie
                                                                                    kwalifikuje go do naprawy
                                                                                    gwarancyjnej lub
                                                                                    zgłoszenie dotyczy serwisu
                                                                                    pozagwarancyjnego, zastrzegamy sobie
                                                                                    prawo
                                                                                    do naliczenia kosztów transportu:
                                                                                    <ul>
                                                                                        <li>
                                                                                            zwykła paczka 24,6 zł.
                                                                                            brutto
                                                                                        </li>
                                                                                        <li>
                                                                                            półpaleta lub paczka powyżej
                                                                                            31,5 kg
                                                                                            123 zł. brutto
                                                                                        </li>
                                                                                        <li>
                                                                                            paleta 246 zł. brutto
                                                                                        </li>
                                                                                    </ul>
                                                                                    Koszty te znajdą się w wycenie
                                                                                    serwisu.
                                                                                    Płatność następuje po zakończeniu
                                                                                    serwisu.
                                                                                </p>
                                                                            </React.Fragment>
                                                                        }
                                                                    >
                                                                        <IconButton>
                                                                            <InfoIcon/>
                                                                        </IconButton>
                                                                    </HtmlTooltip>
                                                                )
                                                                : (<></>)

                                                        }
                                                    </Grid>
                                                    {
                                                        shippingAddress ? (
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Typography sx={{ml: 1, flex: 1}} variant="h6"
                                                                                color={'grey'}
                                                                                component="div">
                                                                        Adres do wysyłki
                                                                        <Divider color={'grey'} fullWidth/>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={5}>
                                                                    <TextField id="outlined-basic" label="Ulica"
                                                                               variant="outlined"
                                                                               fullWidth
                                                                               onChange={(e) => {
                                                                                   changeFormData('shipping_street', e.target.value)
                                                                               }}
                                                                               error={!fieldsValidated['shipping_street']}
                                                                               disabled={false}
                                                                               defaultValue=''/>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <TextField id="outlined-basic" label="Nr budynku"
                                                                               variant="outlined"
                                                                               fullWidth
                                                                               onChange={(e) => {
                                                                                   changeFormData('shipping_street_number', e.target.value)
                                                                               }}
                                                                               error={!fieldsValidated['shipping_street_number']}
                                                                               disabled={false}
                                                                               defaultValue=''/>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <TextField id="outlined-basic" label="Nr lokalu"
                                                                               variant="outlined"
                                                                               fullWidth
                                                                               onChange={(e) => {
                                                                                   changeFormData('shipping_home_number', e.target.value)
                                                                               }}
                                                                               error={!fieldsValidated['shipping_home_number']}
                                                                               disabled={false}
                                                                               defaultValue=''/>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <TextField id="outlined-basic" label="Kod pocztowy"
                                                                               variant="outlined"
                                                                               fullWidth
                                                                               onChange={(e) => {
                                                                                   changeFormData('shipping_postal_code', validate_postal_code(e.target.value, changeFieldValidated, 'shipping_postal_code'))
                                                                               }}
                                                                               error={!fieldsValidated['shipping_postal_code']}
                                                                               disabled={false}
                                                                               defaultValue=''/>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField id="outlined-basic" label="Miasto"
                                                                               variant="outlined"
                                                                               fullWidth
                                                                               onChange={(e) => {
                                                                                   changeFormData('shipping_city', e.target.value)
                                                                               }}
                                                                               error={!fieldsValidated['shipping_city']}
                                                                               disabled={false}
                                                                               defaultValue=''/>
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <AsyncAutocomplete getData={getStates}
                                                                                       label={'Województwo'}
                                                                                       onChange={(e) => changeFormData('shipping_state', e ? e.name : '')}
                                                                                       defaultValue={formData['shipping_state_obj']}
                                                                                       error={!fieldsValidated['shipping_state']}
                                                                                       key={contractorKey}
                                                                                       optionLabel={(option) => option.name}

                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <AsyncAutocomplete getData={getCountries}
                                                                                       label={'Kraj'}
                                                                                       onChange={(e) => {
                                                                                           changeFormData('shipping_country', e ? e.uuid : '');
                                                                                       }}
                                                                                       error={!fieldsValidated['shipping_country']}
                                                                                       optionLabel={(option) => option.name}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider color={'black'} fullWidth/>
                                                                </Grid>
                                                            </>
                                                        ) : <></>
                                                    }
                                                    <Grid item xs={12}>
                                                        <TextField id="outlined-basic" label="Opis usterki"
                                                                   variant="outlined"
                                                                   fullWidth
                                                                   onChange={(e) => {
                                                                       changeFormData('description', e.target.value)
                                                                   }}
                                                                   error={!fieldsValidated['description']}
                                                                   multiline
                                                                   rows={5}
                                                                   disabled={false}
                                                                   defaultValue=''/>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <label style={{fontSize: 17}}>
                                                            <Checkbox
                                                                checked={addPhotos}
                                                                onChange={() => {
                                                                    setAddPhotos(!addPhotos);
                                                                    setPhoto([null, null, null]);
                                                                }}
                                                                name="add_photos"
                                                                color="primary"
                                                            />
                                                            Dodaj zdjęcia usterki
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                    </Grid>
                                                    {
                                                        addPhotos ? (
                                                            <>
                                                                <Grid item xs={4}>
                                                                    <MuiFileInput value={photo[0]}
                                                                                  onChange={(e) => handlePhotoChange(e, 0)}
                                                                                  inputProps={{accept: "image/*"}}
                                                                                  helperText=".png .jpeg ...(1.5MB)"
                                                                                  placeholder={'Dodaj plik'}/>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <MuiFileInput value={photo[1]}
                                                                                  onChange={(e) => handlePhotoChange(e, 1)}
                                                                                  inputProps={{accept: "image/*"}}
                                                                                  helperText=".png .jpeg ...(1.5MB)"
                                                                                  placeholder={'Dodaj plik'}/>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <MuiFileInput value={photo[2]}
                                                                                  onChange={(e) => handlePhotoChange(e, 2)}
                                                                                  inputProps={{accept: "image/*"}}

                                                                                  helperText=".png .jpeg ...(1.5MB)"
                                                                                  placeholder={'Dodaj plik'}/>
                                                                </Grid>
                                                            </>
                                                        ) : <></>
                                                    }
                                                    <Grid item xs={3}>
                                                        <label style={{fontSize: 17}}>
                                                            <Checkbox
                                                                checked={terms}
                                                                onChange={() => {
                                                                    setTerms(!terms);
                                                                }}
                                                                name="set_terms"
                                                                color="primary"
                                                            />
                                                            Akceptuję <a href='http://google.pl'
                                                                         target="_blank"> regulamin</a>
                                                        </label>
                                                    </Grid>
                                                    <Grid item xs={9}>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button fullWidth={true} onClick={handleSave}
                                                                variant="contained"
                                                                color="primary" disabled={!terms}>
                                                            Wyślij zgłoszenie
                                                        </Button>
                                                    </Grid>
                                                </>)}
                                        </Grid>

                                    </Box>) :
                                    (
                                        <Box sx={{paddingBottom: "20px"}} key={defaultKey}>
                                            <div>
                                                <img src={Logo} style={{width: '20vh'}} alt="logo"/>
                                            </div>

                                            <Typography variant="h6">Dziękujemy za dokonanie zgłoszenia.</Typography>
                                            <Typography variant="h6"> Zwyczajowo rozpatrujemy je najpóźniej następnego
                                                dnia roboczego.</Typography>
                                            <Typography variant="h6">W razie pytań podejmiemy kontakt mailowy lub
                                                telefoniczny.</Typography>
                                            <Typography variant="h6">Życzymy miłego dnia,</Typography>
                                            <Typography variant="h6">Zespół Wagner-Service</Typography>
                                            <Button fullWidth={true} onClick={handleNewServiceOrder} variant="contained"
                                                    color="primary">
                                                Dodaj nowe zgłoszenie
                                            </Button>
                                        </Box>
                                    )}
                            </Box>
                        </Item>
                        <LoginPage open={isOpenLoginPage} handleClose={handleCloseLoginPage} login={login}/>
                    </div>
                    <Snackbar open={isOpenSuccess} autoHideDuration={3000} onClose={handleCloseSuccess}
                              anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                        <Alert onClose={handleCloseSuccess} severity={severityMessage} sx={{width: '100%'}}>
                            {message}
                        </Alert>
                    </Snackbar>
                </header>
            </div>
        </ThemeProvider>
    )
        ;
}
export default Form;