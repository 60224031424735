export const defaultFormData = {
    'tax_number': '',
    'contractor_name': '',
    'email': '',
    'phone_number': '',
    'contractor_street': '',
    'contractor_home_number': '',
    'contractor_street_number': '',
    'contractor_postal_code': '',
    'contractor_city': '',
    'contractor_state_obj': null,
    'contractor_state': '',
    'contractor_country_obj': null,
    'contractor_country': '',
    'order_type': null,
    'contractor_type': null,
    'shipping_method': null,
    'shipping_name': null,
}

export const fieldsToRemove = [
    'contractor_state_obj',
    'contractor_country_obj',
    // 'contractor_type'
]

export const fieldsToValidate = [
    'email',
    'phone_number',
    'phone_number',
    // 'contractor_home_number',
    'contractor_street_number',
    'contractor_postal_code',
    'contractor_city',
    'contractor_state',
    'contractor_street',
    'contractor_country',
    'order_type',
    'shipping',
    'shipping_option',
    'contractor_type',
    'device',
    // 'serial_number',
    'description'
]
export const fieldsToValidatedWarranty = [
    // 'purchase_document_number',
    'purchase_document',
    'purchase_date',
]

export const fieldsToValidateCompany = [
    'tax_number',
    'contractor_name',
]

export const fieldsToValidateCustomer = [
    'first_name',
    'last_name',
]

export const fieldsToValidateShipping = [
    'shipping_street',
    // 'shipping_home_number',
    'shipping_street_number',
    'shipping_postal_code',
    'shipping_city',
    'shipping_state',
    'shipping_country'
]

export const fieldsToValidateSelfDelivery = [
    // 'service_address',
]

export const defaultValidated = {
    'tax_number': true,
    'email': true,
    'phone_number': true,
    'contractor_home_number': true,
    'contractor_street_number': true,
    'contractor_postal_code': true,
    'contractor_city': true,
    'contractor_state': true,
    'contractor_street': true,
    'contractor_country': true,
    'shipping_home_number': true,
    'shipping_street_number': true,
    'shipping_postal_code': true,
    'shipping_city': true,
    'shipping_state': true,
    'shipping_street': true,
    'shipping_country': true,
    'order_type': true,
    'shipping': true,
    'purchase_document_number': true,
    'purchase_document': true,
    'purchase_date': true,
    'device': true,
    'serial_number': true,
    'description': true,
    'contractor_type': true,
    //'service_address': true,
    'shipping_method': true,
    'shipping_name': true,
}