import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';


export default function AsyncAutocomplete(props) {
    const {getData, label, defaultValue, optionLabel, disabled, onChange, name, error} = props;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await getData();
            let data = {};
            if (response.data.results) {
                data = response.data.results;
            } else {
                data = response.data;
            }
            if (active) {
                setOptions(Object.keys(data).map((key) => data[key]));
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            // setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-autocomplete"
            title={label}
            // style={{ width: 300 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onChange={(event, newValue) => {
                onChange(newValue, name)
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => value.username === option.username}
            getOptionLabel={optionLabel}
            options={options}
            loading={loading}
            disabled={disabled}
            defaultValue={defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    label={label}
                    title={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
