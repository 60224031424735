const PREFIXES = {
    DOCKER: "http://0.0.0.0:8000",
    LOCAL: "http://127.0.0.1:8000",
  };

  const PREFIX = (process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : PREFIXES.LOCAL) + '/api/v1';


export const API = {
    AUTH: `${PREFIX}/token/`,
    USERS: `${PREFIX}/users/`,
    ORDER_TYPES: `${PREFIX}/order-types/`,
    NEW_ORDER: `${PREFIX}/new-service-orders/`,
    DEVICES: `${PREFIX}/devices/`,
    DEVICES_CATALOGS: `${PREFIX}/devices-catalogs/`,
    STATES: `${PREFIX}/states/`,
    COUNTRIES: `${PREFIX}/countries/`,
    GUS: `${PREFIX}/gus/`,
    SERVICE_ADDRESS: `${PREFIX}/service-address/`,
    SHIPPING_METHODS: `${PREFIX}/shipping-methods/`,
    ORDERS: `${PREFIX}/service-orders/`,
    VALIDATION: `${PREFIX}/orders-validation/`,
    SHIPPING_OPTIONS: `${PREFIX}/shipping-options/`,

}